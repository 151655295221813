<template>
  <transition name="modal-fade">
    <div
      class="absolute z-[10000] top-0 left-0 right-0 bottom-0 flex justify-center items-center"
      data-testid="modal-overlay-container"
      role="dialog"
    >
      <div
        class="overflow-y-auto overflow-x-hidden fixed right-0 left-0 top-0 bottom-0 inset-0 bg-opacity-50 bg-black modal-overlay"
        role="button"
        @click="emit('modal-close')"
      />
      <div
        class="modal z-50 rounded-xl bg-white overflow-auto flex flex-col max-w-xl m-2 fixed max-h-[85vh]"
      >
        <div
          class="p-3 flex flex-row justify-between border-gray-300 items-center"
          :class="{ 'border-b': Boolean(title) }"
        >
          <h3 v-if="title" class="font-sans font-medium text-xl mx-6">
            {{ title }}
          </h3>
          <div
            class="border-gray-300 border rounded-full cursor-pointer hover:bg-gray-100 hover:cursor-pointer"
            @click="emit('modal-close')"
            data-testid="modal-overlay-close"
          >
            <img class="w-4 m-4" :src="closeIconUrl" alt="" />
          </div>
        </div>
        <div class="p-6">
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
import closeIconUrl from '~/assets/icons/close.svg?url'

defineProps<{ title?: string }>()

const emit = defineEmits(['modal-close', 'modal-back'])
</script>

<style scoped>
.modal-fade-enter,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  transition: opacity 0.2s ease;
}
</style>
